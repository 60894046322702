<template>
  <component
    :is="tag"
    @click.native="hideSidebar"
    class="nav-item"
    v-bind="$attrs"
    tag="li"
  >
    <a class="nav-link animated_underline">
      <slot>
        <i v-if="icon" :class="icon"></i>
        <p><RandomiseChars :wordIn="name" /></p>
      </slot>
    </a>
  </component>
</template>

<script>
import RandomiseChars from "@/components/RandomiseChars.vue";

export default {
  name: "sidebar-link",
  inheritAttrs: false,
  components: {
    RandomiseChars,
  },
  inject: {
    autoClose: {
      default: true,
    },
    addLink: {
      default: () => {},
    },
    removeLink: {
      default: () => {},
    },
  },
  props: {
    name: String,
    icon: String,
    tag: {
      type: String,
      default: "router-link",
    },
  },
  methods: {
    hideSidebar() {
      if (this.autoClose) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>

<style scoped lang="scss">
.nav-link:hover,
a:hover,
i:hover,
p:hover {
  scale: 1.1;
  transition: all 0.9s ease-in-out !important;
  transform: translateX(3%);
}
.animated_underline {
  &:after {
    position: absolute;
    content: "";
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid;
    transform: scaleX(0);
    transform-origin: 100% 100%;
    transition: transform 0.6s;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.28, 0.995);
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: 0 0;
    }
  }
}
</style>

