<template>
  <div class="sidebar" :data="backgroundColor">
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <a
          :href="urlWebsite"
          aria-label="sidebar mini logo"
          class="simple-text logo-mini"
        >
          <div class="logo-img" :class="{ 'logo-img-rtl': $rtl.isRTL }">
            <img src="../../../public/img/logo64.png" alt="" />
          </div>
        </a>
        <a :href="urlWebsite" class="simple-text logo-normal">
          <RandomiseChars :wordIn="title" />
        </a>
      </div>
      <slot> </slot>
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot name="links">
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="index"
            :to="link.path"
            :name="link.name"
            :icon="link.icon"
          >
          </sidebar-link>
        </slot>
      </ul>
    </div>
  </div>
</template>

<script>
import RandomiseChars from "../RandomiseChars.vue";
import SidebarLink from "./SidebarLink";

export default {
  props: {
    title: {
      type: String,
      default: "Mutant Wars",
    },
    urlWebsite: {
      type: String,
      default: "https://www.mutantwars.com",
    },
    backgroundColor: {
      type: String,
      default: "vue",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
      addLink: this.addLink,
      removeLink: this.removeLink,
    };
  },
  components: {
    SidebarLink,
    RandomiseChars,
  },
  computed: {
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {{transform: string}}
     */
    arrowMovePx() {
      return this.linkHeight * this.activeLinkIndex;
    },
    shortTitle() {
      return this.title
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase();
    },
  },
  data() {
    return {
      linkHeight: 65,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      links: [],
    };
  },
  methods: {
    findActiveLink() {
      this.links.forEach((link, index) => {
        if (link.isActive()) {
          this.activeLinkIndex = index;
        }
      });
    },
    addLink(link) {
      const index = this.$slots.links.indexOf(link.$vnode);
      this.links.splice(index, 0, link);
    },
    removeLink(link) {
      const index = this.links.indexOf(link);
      if (index > -1) {
        this.links.splice(index, 1);
      }
    },
  },
  mounted() {
    this.$watch("$route", this.findActiveLink, {
      immediate: true,
    });
  },
};
</script>

<style scoped lang="scss">
.logo:hover,
.simple-text:hover,
.logo-normal:hover {
  transition: all 1s ease-in-out !important;
  scale: 1.1;
}

.animated_underline {
  &:after {
    position: absolute;
    content: "";
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid;
    transform: scaleX(0);
    transform-origin: 100% 100%;
    transition: transform 0.6s;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.28, 0.995);
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: 0 0;
    }
  }
}

.sidebar-wrapper {
  background-color: #17171f;
  transition: all 2s ease-in-out !important;
  -webkit-filter: drop-shadow(0px 0px 4px rgba(43, 61, 64, 0.8));
}

.sidebar-wrapper:hover {
  background-color: #0b6890;
  -webkit-filter: drop-shadow(0px 0px 6px rgba(26, 132, 150, 0.943));
}
</style>

