import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";

const Nfts = () => import(/* webpackChunkName: "common" */ "@/pages/Nfts.vue");

const Home = () => import(/* webpackChunkName: "common" */ "@/pages/Home.vue");

const Disclaimer = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Disclaimer.vue");

const Icons = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");

const GamePvp = () =>
  import(/* webpackChunkName: "common" */ "@/pages/GamePvp.vue");

const GameVisualization = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Home/GameCard.vue");

const NotFound = () =>
  import(/* webpackChunkName: "common" */ "@/pages/NotFoundPage.vue");

const ComingSoon = () =>
  import(/* webpackChunkName: "common" */ "@/pages/ComingSoon.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "home",
        component: Home,
      },
      {
        path: "disclaimer",
        name: "disclaimer",
        component: Disclaimer,
      },
      // {
      //   path: "nfts",
      //   name: "nfts",
      //   // component: Nfts,
      //   component: ComingSoon,
      // },
      {
        path: "mint",
        name: "mint",
        component: ComingSoon,
      },
      {
        path: "staking",
        name: "staking",
        component: ComingSoon,
      },
      {
        path: "leveling",
        name: "leveling",
        component: ComingSoon,
      },
      {
        path: "icons",
        name: "icons",
        component: Icons,
      },
      {
        path: "Game",
        name: "Game",
        component: ComingSoon,
        // component: GamePvp,
      },
      // {
      //   path: "Game-Vis",
      //   name: "Game Visualization",
      //   component: GameVisualization,
      // },
      {
        path: "404",
        name: "back to safety",
        component: NotFound,
      },
    ],
  },
  {
    path: "*",
    redirect: "/404",
  },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
