<template>
  <div @mouseover="replaceChars" @reset="iterations = 0">{{ currWord }}</div>
</template>

<script>
export default {
  props: {
    wordIn: String,
  },
  data() {
    return {
      currWord: "",
      iterations: 0,
      targetIterations: 12,
      lastIntervalId: null,
    };
  },
  methods: {
    replaceChars() {
      if (this.lastIntervalId !== null) return;
      const that = this;
      that.lastIntervalId = setInterval(() => {
        let _word = "";
        for (let i = 0; i < that.wordIn.length; ++i) {
          if (
            that.iterations <
            (i * that.targetIterations) / that.wordIn.length
          ) {
            _word += String.fromCharCode(Math.floor(Math.random() * 26) + 97);
          } else {
            _word += that.wordIn[i];
          }
        }
        that.currWord = _word;
        ++that.iterations;
        if (this.iterations > this.targetIterations) {
          clearInterval(that.lastIntervalId);
          that.iterations = 0;
          that.lastIntervalId = null;
        }
      }, 100);
    },
  },
  mounted() {
    this.currWord = this.wordIn;
  },
};
</script>
